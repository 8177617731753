<!-- src/components/AppHeader.vue -->
<template>
  <header class="glassmorphism fixed w-full top-0 z-10 py-4">
    <div class="container mx-auto px-4">
      <h1 class="text-4xl sm:text-5xl font-bold text-center text-hot-pink">
        √<span class="text-purple-500">endur</span>
      </h1>
    </div>
  </header>
</template>

<script>
export default {
  name: 'AppHeader'
}
</script>

<style scoped>
.glassmorphism {
  background: rgba(26, 32, 44, 0.7);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
</style>