<!-- src/App.vue -->
<template>
  <div class="min-h-screen bg-gray-900 text-gray-100 font-crypto">
    <AppHeader />
    <div class="container mx-auto px-4 py-24">
      <div class="text-center mb-8 h-8">
        <transition name="fade" mode="out-in">
          <p :key="currentActivityIndex" class="text-lg text-gray-300 typing-effect">{{ currentActivity }}</p>
        </transition>
      </div>

      <!-- Ad box before the form -->
      <div class="max-w-md mx-auto bg-gray-800 rounded-lg shadow-subtle p-4 mb-8 text-center">
        <p class="text-gray-400">{{ adContent }}</p>
      </div>
      
      <div class="max-w-md mx-auto bg-gray-800 rounded-lg shadow-subtle p-6 mb-8">
        <div class="flex justify-center mb-6">
          <button 
            @click="setMode('buy')" 
            :class="{'bg-hot-pink text-gray-900': mode === 'buy'}"
            class="px-6 py-2 rounded-l-full transition-all duration-300 ease-in-out focus:outline-none"
          >
            Buy
          </button>
          <button 
            @click="setMode('sell')" 
            :class="{'bg-purple-500 text-gray-900': mode === 'sell'}"
            class="px-6 py-2 rounded-r-full transition-all duration-300 ease-in-out focus:outline-none"
          >
            Sell
          </button>
        </div>
        
        <form @submit.prevent="handleSubmit">
          <div class="mb-4">
            <label class="block text-sm font-bold mb-2" for="crypto">
              Cryptocurrency
            </label>
            <select 
              v-model="selectedCrypto" 
              id="crypto" 
              class="w-full bg-gray-700 rounded-full px-4 py-2 focus:outline-none focus:ring-2 focus:ring-hot-pink"
            >
              <option v-for="crypto in cryptoList" :key="crypto" :value="crypto">
                {{ crypto }}
              </option>
            </select>
          </div>
          
          <div class="mb-4">
            <label class="block text-sm font-bold mb-2" :for="mode === 'buy' ? 'fiatAmount' : 'cryptoAmount'">
              {{ mode === 'buy' ? 'Fiat Amount' : 'Crypto Amount' }}
            </label>
            <input 
              v-model="amount" 
              :id="mode === 'buy' ? 'fiatAmount' : 'cryptoAmount'" 
              type="number" 
              step="0.01" 
              class="w-full bg-gray-700 rounded-full px-4 py-2 focus:outline-none focus:ring-2 focus:ring-hot-pink"
              placeholder="Enter amount"
              @input="calculateExchangeAmount"
            >
          </div>
          
          <div class="mb-6">
            <label class="block text-sm font-bold mb-2" for="exchangeAmount">
              {{ mode === 'buy' ? 'Crypto to Receive' : 'Fiat to Receive' }}
            </label>
            <input 
              :value="exchangeAmount" 
              id="exchangeAmount" 
              type="text" 
              readonly 
              class="w-full bg-gray-600 rounded-full px-4 py-2"
            >
          </div>
          
          <p class="text-xs text-gray-400 mb-4">Our charge: 2% per transaction</p>

          <button 
            type="submit" 
            :class="{'bg-hot-pink hover:bg-pink-600': mode === 'buy', 'bg-purple-500 hover:bg-purple-600': mode === 'sell'}"
            class="w-full text-gray-900 font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline transition-all duration-300 ease-in-out transform hover:scale-105"
          >
            {{ mode === 'buy' ? 'Pay Now' : 'Sell Now' }}
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from './components/AppHeader.vue'

export default {
  components: {
    AppHeader
  },
  data() {
    return {
      mode: 'buy',
      selectedCrypto: 'BTC',
      amount: '',
      exchangeAmount: '',
      cryptoList: [
        'BTC', 'ETH', 'XRP', 'LTC', 'ADA', 'SOL', 'DOGE', 'SHIB', 'PEPE', 
        'DOT', 'LINK', 'UNI', 'MATIC', 'XLM', 'ATOM', 'AVAX', 'TRX', 'ETC'
      ],
      activities: [
        "Instant crypto purchases",
        "Seamless fiat-to-crypto exchanges",
        "Secure transactions",
        "Multiple cryptocurrencies supported"
      ],
      currentActivityIndex: 0,
      adminNotification: "Welcome to Vendur! Enjoy our low fees and fast transactions.",
      adContent: "Advertise Here",
    }
  },
  computed: {
    currentActivity() {
      return this.activities[this.currentActivityIndex]
    }
  },
  methods: {
    setMode(newMode) {
      this.mode = newMode
      this.amount = ''
      this.exchangeAmount = ''
    },
    calculateExchangeAmount() {
      if (!this.amount) {
        this.exchangeAmount = ''
        return
      }
      const rate = this.mode === 'buy' ? 0.00002 : 50000
      const fee = this.mode === 'buy' ? 1.02 : 0.98 // 2% fee
      this.exchangeAmount = (this.amount * rate * fee).toFixed(8)
    },
    handleSubmit() {
      // Here you would typically make an API call to process the transaction
      console.log('Transaction submitted', {
        mode: this.mode,
        crypto: this.selectedCrypto,
        amount: this.amount,
        exchangeAmount: this.exchangeAmount
      })
      alert(`${this.mode.toUpperCase()} order submitted successfully!`)
      this.amount = ''
      this.exchangeAmount = ''
    },
    rotateActivity() {
      this.currentActivityIndex = (this.currentActivityIndex + 1) % this.activities.length
    }
  },
  mounted() {
    setInterval(this.rotateActivity, 3000) // Change activity every 3 seconds
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap');

.font-crypto {
  font-family: 'Orbitron', sans-serif;
}

.text-hot-pink {
  color: #FF69B4;
}

.bg-hot-pink {
  background-color: #FF69B4;
}

.shadow-subtle {
  box-shadow: 0 0 10px rgba(255, 105, 180, 0.3);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>